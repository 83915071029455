import {
  arrayWithUniqueValues,
  Logger,
} from 'utils';
import type { CommunicationBarQuery } from '#gql';
import type { CommunicationBarPage } from 'configs';

const useCommunicationBar = () => {
  const GqlInstance = useGql();
  const { locale } = useI18n();
  const { setFilterScaffold } = useFilterScaffold();

  const activeCommunicationBar = useState<CommunicationBarQuery['communicationBar'] | null>('activeCommunicationBar', () => null);

  const initCommunicationBar = async ({
    page,
    productFilter,
  }: {
    page: CommunicationBarPage;
    productFilter?: string[];
  }) => {
    const filterScaffold = await setFilterScaffold();
    const allProductFilters = filterScaffold.find((filter) => filter.key === 'product')?.filters.map((filter) => ({
      childKeys: 'subItems' in filter
        ? filter.subItems.map((subItem) => subItem.key)
        : [],
      parentKey: filter.key,
    }));

    const productFilterWithParentKeys = arrayWithUniqueValues(productFilter?.flatMap((filter) => {
      const filterList = [ filter ];

      allProductFilters?.forEach((f) => {
        if (f.childKeys.includes(filter)) {
          filterList.push(f.parentKey);
        } else if (f.parentKey === filter) {
          filterList.push(...(f.childKeys ?? []));
        }
      });

      return filterList;
    }) ?? []);

    try {
      const { communicationBar } = await GqlInstance('CommunicationBar', {
        localeCode: locale.value,
        page,
        productFilter: productFilterWithParentKeys,
      });

      activeCommunicationBar.value = communicationBar;
    } catch (e) {
      Logger.warn('Error fetching communication bar', { e });
      activeCommunicationBar.value = null;
    }
  };

  return {
    activeCommunicationBar,
    initCommunicationBar,
  };
};

export { useCommunicationBar };
